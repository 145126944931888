/*
The following stylesheet was taken from Chip's mockup at: http://mx-chipd.rhcloud.com/css/media-exchange.css
*/

/* Media Exchange Admin and Partner Tool - CSS Document */

/* Foundational */
h1, h2, h3, p, body, li, td, th, span {font-family: 'Lato', sans-serif;}
a {color: #1B6B9E;}


body {
	margin: 0px;
}