.loader {
	z-index: 1;
}

.loader--active {
	background: rgba(0,0,0,.7) none;
	bottom: 0;
	left: 0;
	position: fixed;
    right: 0;
    top: 0;
}

.loader--active .loader-circle,
.loader--active .loader-line-mask {
	display: block;
}

.loader-circle {
	border-radius: 50%;
	box-shadow: inset 0 0 0 4px rgba(255, 255, 255, .1);
	display: none;
	height: 120px;
	left: 50%;
	margin-left: -60px;
	margin-top: -60px;
	position: absolute;
	top: 50%;
	width: 120px;
}

.loader-line-mask {
	-ms-animation: rotate 1.2s infinite linear;
	-webkit-animation: rotate 1.2s infinite linear;
	-moz-animation: rotate 1.2s infinite linear;
	-o-animation: rotate 1.2s infinite linear;
	animation: rotate 1.2s infinite linear;
	display: none;
	height: 120px;
	left: 50%;
	margin-left: -60px;
	margin-top: -60px;
	-webkit-mask-image: -webkit-linear-gradient(top, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
	overflow: hidden;
	position: absolute;
	top: 50%;
	-webkit-transform-origin: 60px;
	-moz-transform-origin: 60px;
	transform-origin: 60px;
	width: 60px;
}

.loader-line {
	height: 120px;
	border-radius: 50%;
	box-shadow: inset 0 0 0 4px rgba(255, 255, 255, .75);
	width: 120px;
}

@-webkit-keyframes rotate {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@-webkit-keyframes fade {
	0% {
		opacity: 1;
	}
	50% {
		opacity: .25;
	}
}

@-webkit-keyframes fade-in {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}