/* This works because the border width decreases as we go down the element,
	and technically the colorized content is the shown border
*/
.down-arrow:after {
	content: ' ';
	border-color: #9e9393 transparent;
	border-style: solid;
	border-width: 6px 6px 0px 6px;
	height: 0px;
	width: 0px;
	position: relative;
	top: 1em;
	left: 0.25em;
}

.up-arrow:after {
	content: ' ';
	border-color: #9e9393 transparent;
	border-style: solid;
	border-width: 0px 6px 6px 6px;
	height: 0px;
	width: 0px;
	position: relative;
	top: -0.75em;
	left: 0.25em;
}
