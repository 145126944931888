dl.one-liners dt {
	display: inline-block;
}

dt::after {
    content: ': ';
	white-space: pre;
}
dl.one-liners dd {
	display: inline;
	margin: 0
}

dl.one-liners dd::after {
	display: block;
	content: ''
}
